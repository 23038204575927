import { GQLSEOQuery } from "@src/graphql.gatsby.generated";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";

interface ISEOProps {
    description: string;
    lang: string;
    meta: any;
    title: string;
    link?: any;
    openGraphDescription?: string;
    openGraphTitle?: string;
    openGraphImage?: any;
}

const generateImageUrl = ({ src, width }: any, aspectRatio: number): string => {
    return src.replace("$resizeWidth", String(width)).replace("$resizeHeight", String(Math.ceil(width / aspectRatio)));
};

function SEO({ description, meta, title, link, openGraphDescription, openGraphTitle, openGraphImage }: ISEOProps) {
    const { site } = useStaticQuery<GQLSEOQuery>(
        graphql`
            query SEO {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `,
    );

    const metaDescription = description ?? site?.siteMetadata?.description ?? "";

    const ogImage = openGraphImage?.block?.urlTemplate
        ? {
              property: "og:image",
              content: generateImageUrl(
                  {
                      src: openGraphImage.block.urlTemplate,
                      width: 1024,
                  },
                  1 / 1,
              ),
          }
        : {
              property: "og:image",
              content: "",
          };

    return (
        <Helmet
            title={title}
            titleTemplate={`%s`}
            link={link}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: openGraphTitle || title,
                },
                {
                    property: `og:description`,
                    content: openGraphDescription || metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                ogImage,
            ].concat(meta)}
        />
    );
}

SEO.defaultProps = {
    lang: `de`,
    meta: [],
    description: ``,
};

export default SEO;
