import * as React from "react";

import { DisplayNameContext } from "./DisplayNameContext";

export default function useDisplayName() {
    const context = React.useContext(DisplayNameContext);

    if (context === null) {
        throw new Error(
            "No DisplayNameContext instance can be found. Please ensure that you have called 'DisplayNameProvider' higher up in your tree.",
        );
    }

    return context;
}
