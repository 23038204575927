import styled, { css } from "styled-components";

import { LoadingSpinnerVariant } from "./LoadingSpinner";

interface IProps {
    variant: LoadingSpinnerVariant;
}

export const LoadingRingWrapper = styled.div<IProps>`
    display: inline-block;
    position: relative;
    margin-right: 10px;

    ${({ variant }) =>
        variant === LoadingSpinnerVariant.Small
            ? css`
                  width: 20px;
                  height: 20px;
              `
            : css`
                  width: 30px;
                  height: 30px;
              `};

    &div:nth-child(1) {
        animation-delay: -0.45s;
    }
    & div:nth-child(2) {
        animation-delay: -0.3s;
    }
    & div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const LoadingRing = styled.div<IProps>`
    box-sizing: border-box;
    display: block;
    position: absolute;

    ${({ variant }) =>
        variant === LoadingSpinnerVariant.Small
            ? css`
                  width: 20px;
                  height: 20px;
              `
            : css`
                  width: 30px;
                  height: 30px;
              `};

    border: 4px solid ${({ theme }) => theme.colors.porscheBankBlue};
    border-radius: 50%;
    animation: rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme }) => theme.colors.porscheBankBlue} transparent transparent transparent;
`;
