import { getConfig } from "@src/config";
import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import getFormattedNumber from "@src/shared/utils/getFormattedNumber";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import Slider, { ISliderProps } from "../../../../slider/Slider";
import { BudgetInput, BudgetInputWrapper, Caption, CaptionContainer, Root } from "./SliderFilter.sc";

interface ISliderFilterProps extends ISliderProps {
    captionStyling?: "row" | "column";
    currencySign?: string;
    hasEndAdornment?: boolean;
    infoIconText?: React.ReactNode;
}

const SliderFilter: React.FunctionComponent<ISliderFilterProps> = ({
    onChange,
    value,
    minValue = 0,
    maxValue = 100,
    captionStyling = "column",
    currencySign = "€",
    hasEndAdornment = false,
    infoIconText,
}) => {
    const [internalValue, setInternalValue] = React.useState<number | null>(value);
    const [isMaxValue, setIsMaxValue] = React.useState<boolean>();

    React.useEffect(() => {
        setInternalValue(value);
        setIsMaxValue(maxValue === value);
    }, [value]);
    const handleInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.currentTarget.value.replace(".", "");

        if (!inputValue) {
            setInternalValue(null);
            return;
        }

        // Digits only allowed
        if (!/^\d+$/.test(inputValue)) {
            setInternalValue(minValue);
            return;
        }

        const newValue = Math.min(Number(inputValue), maxValue);
        setInternalValue(newValue);

        const isArrowClick = Math.abs(newValue - value) === 1;

        if (isArrowClick) {
            onChange?.(newValue);
        }
    };

    const handleSliderValueChange = React.useCallback((value: number) => {
        setInternalValue(value);
    }, []);

    const handleBlur = () => {
        const newValue = clampValue(internalValue ?? 0, minValue, maxValue);
        setInternalValue(newValue);
        onChange?.(newValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleBlur();
        }
    };

    return (
        <Root captionStyling={captionStyling}>
            <CaptionContainer captionStyling={captionStyling}>
                {captionStyling === "column" && (
                    <Caption captionStyling="column">
                        <FormattedMessage id="leaseme.filter.budget" defaultMessage="Budget pro Monat" />
                    </Caption>
                )}
                <BudgetInputWrapper
                    isMaxValue={isMaxValue}
                    captionStyling={captionStyling}
                    currencySign={currencySign}
                    valueLength={String(internalValue).length}
                    hasEndAdornment={hasEndAdornment || getConfig("scope").domain === "car4me.porschefinance.hu"}
                >
                    <BudgetInput
                        type="text"
                        value={internalValue ? getFormattedNumber(internalValue) : ""}
                        onChange={handleInputValueChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        valueLength={String(internalValue).length}
                        inputMode="numeric"
                    />
                </BudgetInputWrapper>
            </CaptionContainer>
            <Slider value={value} minValue={minValue} maxValue={maxValue} onChange={handleSliderValueChange} onBlur={handleBlur} />
            {captionStyling === "row" && (
                <Caption captionStyling={captionStyling}>
                    <FormattedMessage id="leaseme.filter.budget" defaultMessage="Budget pro Monat" />
                    {infoIconText && <InfoIcon infoText={infoIconText} />}
                </Caption>
            )}
        </Root>
    );
};
export default SliderFilter;

const clampValue = (value: number, minValue: number, maxValue: number) => Math.max(Math.min(value, maxValue), minValue);
