import styled, { css } from "styled-components";

interface CaptionProps {
    captionStyling?: "row" | "column";
}

export const Root = styled.div<CaptionProps>`
    padding: 0;

    ${({ captionStyling = "column" }) =>
        captionStyling === "column" &&
        css`
            padding: 0 20px;
        `}

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        border-bottom: none;
        padding: 0 20px;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        padding: 0;
    }

    ${({ captionStyling }) =>
        captionStyling === "column" &&
        css`
            margin-bottom: 16px;
        `}
`;

interface IProps {
    captionStyling?: "row" | "column";
    hideMobile?: boolean;
}

export const CaptionContainer = styled.div<CaptionProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ captionStyling = "column" }) =>
        captionStyling === "row" &&
        css`
            justify-content: center;
        `}

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        justify-content: center;
    }

    ${({ captionStyling }) =>
        captionStyling === "column" &&
        css`
            padding: 20px 0px;
            gap: 16px;
        `}
`;

export const Caption = styled.div<IProps>`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    letter-spacing: 0;
    line-height: 24px;
    flex: 1;

    font-size: 16px;
    font-weight: 300;
    padding-top: 20px;

    ${({ captionStyling = "column" }) =>
        captionStyling === "row" &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
        `}

    ${({ hideMobile }) =>
        hideMobile &&
        css`
            display: none;
        `}

    ${({ captionStyling }) =>
        captionStyling === "column" &&
        css`
            padding-top: 0;
            max-width: fit-content;
        `}

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        ${({ captionStyling = "column", hideMobile }) =>
            (captionStyling === "row" || hideMobile) &&
            css`
                display: flex;
                justify-content: center;
                align-items: center;
            `}
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        ${({ captionStyling = "column" }) =>
            captionStyling === "column" &&
            css`
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
            `}
    }
`;

interface BudgetInputProps {
    valueLength: number;
}

export const BudgetInput = styled.input<BudgetInputProps>`
    height: 36px;
    background: none;
    border: none;
    outline: none;
    min-width: ${({ valueLength }) => (valueLength > 4 ? "7.5rem" : valueLength > 3 ? "5.5rem" : "3.5rem")};
    width: ${({ valueLength }) => (valueLength > 4 ? "7.5rem" : valueLength > 3 ? "5.5rem" : "3.5rem")};
    text-align: center;
    padding: 0;
`;

interface IBudgetInputWrapper {
    isMaxValue?: boolean;
    captionStyling?: "row" | "column";
    currencySign: string;
    valueLength: number;
    hasEndAdornment?: boolean;
}

export const BudgetInputWrapper = styled.div<IBudgetInputWrapper>`
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 0 10px;
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    display: flex;

    ${({ captionStyling = "column" }) =>
        captionStyling === "column" &&
        css`
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            margin-bottom: 0;
        `}

    ${({ captionStyling = "column" }) =>
        captionStyling === "row" &&
        css`
            text-align: center;
        `}

    ${({ hasEndAdornment, currencySign }) =>
        hasEndAdornment
            ? css`
                  ::after {
                      content: "${currencySign}";
                      margin-left: 5px;
                  }
              `
            : css`
                  ::before {
                      content: "${currencySign}";
                      margin-right: 5px;
                  }
              `}
    
    

    ${BudgetInput}, ::before, ::after {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 36px;
        text-align: center;
        font-family: inherit;

        ${({ captionStyling }) =>
            captionStyling === "column" &&
            css`
                height: auto;
            `}
        ${({ theme }) => theme.breakpoints.md.mediaquery} {
            font-size: 28px;
            line-height: 36px;
        }
    }

    ${BudgetInput} {
        ${({ theme }) => theme.breakpoints.md.mediaquery} {
            ${({ captionStyling, valueLength }) =>
                captionStyling === "column" &&
                css`
                    width: ${valueLength > 3 ? "4rem" : "3rem"};
                `}
        }
    }

    ${({ isMaxValue, currencySign, hasEndAdornment }) =>
        isMaxValue &&
        css`
            ::after {
                content: "+ ${hasEndAdornment && currencySign}";
                margin-left: 5px;
            }
        `}
`;

export const CaptionMobileContainer = styled.div`
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;
