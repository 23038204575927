import styled from "styled-components";

export const Root = styled.div`
    height: 24px;
    padding: 10px 0;

    .noUi-target {
        cursor: pointer;
        border: none;
        box-shadow: none;
        border-radius: 0;
    }

    .noUi-connects {
        transform: translateZ(0);
    }

    .noUi-connect {
        will-change: auto;
        background: ${({ theme }) => theme.colors.porscheBankBlue};
    }

    .noUi-handle {
        top: -7px;
        right: -12px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
        border: 2px solid ${({ theme }) => theme.colors.porscheBankBlue};
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        outline: none;

        &::before,
        &::after {
            display: none;
        }
    }
`;

export const SliderBar = styled.div`
    height: 10px;
    background: ${({ theme }) => theme.colors.borderLightGray};
`;
