import "nouislider/distribute/nouislider.css";

import * as noUiSlider from "nouislider";
import * as React from "react";

import { Root, SliderBar } from "./Slider.sc";

export interface ISliderProps {
    value: number;
    minValue?: number;
    maxValue?: number;
    onChange?: (value: number) => void;
    onBlur?: () => void;
}

const Slider: React.FunctionComponent<ISliderProps> = ({ onChange, onBlur, value, minValue = 0, maxValue = 100 }) => {
    const sliderRef = React.useRef<noUiSlider.noUiSlider>();
    const sliderDomRef = React.useRef<HTMLDivElement>(null);
    const initialValue = React.useRef(value);

    React.useEffect(() => {
        if (sliderDomRef.current) {
            const range66 = maxValue / 3.75;
            const range92 = maxValue / 1.25;
            sliderRef.current = noUiSlider.create(sliderDomRef.current, {
                start: initialValue.current,
                range: {
                    min: minValue,
                    "66%": [range66, 20],
                    "92%": [range92, 50],
                    max: maxValue,
                },
                behaviour: "snap",
                connect: [true, false],
            });
        }

        return () => {
            sliderRef.current?.destroy();
        };
    }, [minValue, maxValue]);

    React.useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.off("update");
            sliderRef.current.on("update", (values: number[]) => {
                onChange?.(Math.round(values[0]));
            });
            sliderRef.current.off("change");
            sliderRef.current.on("change", (values: number[]) => {
                onChange?.(Math.round(values[0]));
            });
        }
    }, [onChange]);

    React.useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.off("end");
            sliderRef.current.on("end", () => {
                onBlur?.();
            });
        }
    }, [onBlur]);

    React.useEffect(() => {
        sliderRef.current?.set(value);
    }, [value]);

    return (
        <Root>
            <SliderBar ref={sliderDomRef} />
        </Root>
    );
};
export default Slider;
