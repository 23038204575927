import { getStartAdornmentPaddingInputFilterFromScope } from "@src/skeleton/utils/scopedStyles";
import styled, { css } from "styled-components";

interface IRootProps {
    startAdornment?: string;
    endAdornment?: string;
    variant?: "default" | "dark";
    size: "small" | "default";
    startAdornmentWeight?: "bold" | "default";
    startAdornmentPadding?: number;
    endAdornmentWeight?: "bold" | "default";
    endAdornmentPadding?: number;
}

export const Root = styled.div<IRootProps>`
    position: relative;
    height: ${({ size }) => (size === "small" ? "40px" : "50px")};
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-size: 16px;
    color: ${({ theme, variant }) => (variant === "dark" ? theme.colors.white : theme.colors.porscheBankBlue)};

    ${({ startAdornment, size, startAdornmentWeight, startAdornmentPadding }) =>
        startAdornment &&
        css`
            &::before {
                content: "${startAdornment}";
                position: absolute;
                top: 0;
                left: 0;
                width: 12px;
                text-align: center;
                line-height: ${size === "small" ? "40px" : "50px"};
                font-weight: ${startAdornmentWeight === "bold" ? "700" : "400"};
                font-size: 16px;
                padding-left: ${startAdornmentPadding}px;
            }
        `}

    ${({ endAdornment, size, endAdornmentWeight, endAdornmentPadding }) =>
        endAdornment &&
        css`
            &::after {
                content: "${endAdornment}";
                position: absolute;
                top: 0;
                right: 0;
                width: 36px;
                text-align: center;
                line-height: ${size === "small" ? "40px" : "50px"};
                font-weight: ${endAdornmentWeight === "bold" ? "700" : "400"};
                font-size: 16px;
                padding-right: ${endAdornmentPadding}px;
            }
        `}


    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
`;

interface IInputProps {
    hasStartAdornment?: boolean;
    hasEndAdornment?: boolean;
    variant?: "default" | "dark";
    endAdornment: string;
    startAdornmentPadding?: number;
    endAdornmentPadding?: number;
}

export const Input = styled.input<IInputProps>`
    padding: 0;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    border-radius: 1px;
    padding-left: ${({ startAdornmentPadding, hasStartAdornment }) =>
        hasStartAdornment ? (startAdornmentPadding || 0) + getStartAdornmentPaddingInputFilterFromScope() : 10}px;
    padding-right: ${({ endAdornmentPadding, hasEndAdornment }) => (hasEndAdornment ? (endAdornmentPadding || 0) + 44 : 8)}px;
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-size: 16px;
    color: ${({ theme, variant }) => (variant === "dark" ? theme.colors.white : theme.colors.porscheBankBlue)};
    -webkit-text-fill-color: ${({ theme, variant }) => (variant === "dark" ? theme.colors.white : theme.colors.porscheBankBlue)};
    opacity: 1;

    :disabled {
        background-color: ${({ theme }) => theme.colors.white};
    }

    ${({ variant }) =>
        variant === "dark" &&
        css`
            font-weight: bold;
            background-color: transparent;
            border-color: transparent;
        `}
`;
