import * as React from "react";

import { LoadingRing, LoadingRingWrapper } from "./LoadingSpinner.sc";

export enum LoadingSpinnerVariant {
    Default = "default",
    Small = "small",
}

interface ILoadingSpinnerProps {
    variant?: LoadingSpinnerVariant;
}

const LoadingSpinner: React.FunctionComponent<ILoadingSpinnerProps> = ({ variant = LoadingSpinnerVariant.Default }) => {
    return (
        <LoadingRingWrapper variant={variant}>
            <LoadingRing variant={variant} />
            <LoadingRing variant={variant} />
            <LoadingRing variant={variant} />
            <LoadingRing variant={variant} />
        </LoadingRingWrapper>
    );
};

export default LoadingSpinner;
