import { getConfig } from "@src/config";

export function getStartAdornmentPaddingInputFilterFromScope() {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return 26;
        default:
            return 16;
    }
}
